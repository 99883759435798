<template>
  <div class="viewContainer csv">
    <h1>VenueWine Listen Upload</h1>
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <label class="text-reader">
              <input type="file" @change="loadTextFromFile" />
            </label>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-form-group label="CSV Dateityp:" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="fileType"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <b-form-radio :value="VENUEWINE_TYPE">Weine</b-form-radio>
                <b-form-radio :value="REGION_TYPE">Regionen</b-form-radio>
                <b-form-radio :value="GRAPE_TYPE">Rebsorten</b-form-radio>
                <b-form-radio :value="EK">EK Überschreiben</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <br />
            <b-form-checkbox
              v-model="importOnlyWine"
              inline
              id="checkbox-import-only-wine"
              name="checkbox-import-only-wine"
              :disabled="fileType != VENUEWINE_TYPE"
            >
              - Wine-Import aus Master-Liste
            </b-form-checkbox>
            <b-row
              >Dadurch werden NUR Wines importiert und VenueWines
              Übersprungen</b-row
            >

            <b-row class="m-3"></b-row>

            <b-form-checkbox
              v-model="skipImportWithErrors"
              inline
              id="checkbox-skip-missing-wine"
              name="checkbox-skip-missing-wine"
              :disabled="fileType != VENUEWINE_TYPE"
            >
              - Überspringe Import Zeile, wenn Weindaten fehlerhaft und
              protokolliere Fehler.
            </b-form-checkbox>
            <b-row
              >NICHT aktivieren bei Master-Liste Import. (Wenn man sicher ist,
              dass alle Daten korrekt sind, sollten diese nicht übersprungen
              werden)</b-row
            >

            <b-row class="m-3"></b-row>

            <b-form-checkbox
              v-model="flagErrorsWithVenueId"
              inline
              id="checkbox-flag-error-import"
              name="checkbox-flag-error-import"
              :disabled="fileType != VENUEWINE_TYPE"
            >
              - Fehlerhafte Wines bei Import mit Venue ID flaggen.
            </b-form-checkbox>
            <b-row
              >NICHT aktivieren bei Master-Liste Import (Wenn man sicher ist,
              dass alle Daten korrekt sind, sollten diese nicht geflagged
              werden)</b-row
            >

            <b-row class="m-3"></b-row>

            <b-form-checkbox
              v-model="limitImport"
              inline
              id="checkbox-limit-import"
              name="checkbox-limit-import"
            >
              - Import Anzahl begrenzen. (Nützlich für Probedurchgänge)
            </b-form-checkbox>

            <b-row v-if="limitImport">
              <b-col cols="2">
                <b-form-input
                  v-model="limitImportCount"
                  placeholder="0"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="m-2"></b-row>

            <b-form-checkbox
              v-model="protocolActive"
              inline
              id="checkbox-protocol-active"
              name="checkbox-protocol-active"
            >
              - Protokoll für Verlauf aktivieren (Nützlich für Fehlersuche)
            </b-form-checkbox>
           
        <b-row class="mt-2">
          <b-col cols="3">
            Datum des Imports setzen:
        <b-form-datepicker :locale="$i18n.locale" id="fromDatePicker" v-model="importDate">
      
        </b-form-datepicker>
          </b-col>
        </b-row>
            <b-row class="mt-4">
              <b-button class="w-25" @click="startImport()"
                >Starte Import</b-button
              >
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="4" class="p-4">
        <b-row>
          <h2>
            Protokoll: {{ importingWineIndex }}/{{ importingWinesLength }}
          </h2>
        </b-row>
        <b-row>
          <textarea
            id="protocol-textarea"
            v-model="progress"
            placeholder="Starte einen Import mit CSV Datei"
            style="height: 600px"
          ></textarea>
        </b-row>
      </b-col>

      <b-col cols="8" class="p-4">
        <b-row>
          <b-col>
            <h2>NICHT Importierte Weine:</h2>
          </b-col>
          <b-col>
            <b-button
              variant="link"
              v-if="protocolTableData.length > 0"
              @click="downloadCSV()"
              >Download CSV</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-table
            ref="notImportedWineList"
            small
            sticky-header
            :items="protocolTableData"
            :fields="fields"
          >
          </b-table>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
const VENUEWINE_TYPE = "venueWine"; // import venueWines
const REGION_TYPE = "regions"; // import only regions
const GRAPE_TYPE = "grapeVarieties"; // import only grapeVarieties
const EK = "ek"; // import venueWines and overwrite only EKå

import DataService from "@/services/StrapiService";
import { assignObjectFromPath, equalsIgnoreCase, parsePotentiallyGroupedFloat } from "@/utils/functions";
import { applyMandatoryRatingAgencies, addSupplierAndPrice, getWineNameNullChecked  } from '@/utils/venueWineFunctions'
import { mapGetters } from 'vuex'
import Winery from '@/model/Winery.js'
import StrapiService from "../services/StrapiService";

export default {
  data() {
    return {
      skipImportWithErrors: false,
      flagErrorsWithVenueId: false,
      importOnlyWine: false,
      limitImport: false,
      protocolActive: false,
      limitImportCount: 0,
      file: "",
      csvInput: [],
      progress: [],
      importingWineIndex: 0,
      importingWinesLength: 0,
      protocolTableData: [],
      protocolTableDataCSV: "",
      offlineWeingut: [],
      offlineRebsorte: [],
      offlineWineSupplier: [],
      offlineBottleSize: [],
      offlineLand: [],
      offlineWineType: [],
      offlineRegion: [],
      offlineWine: [],
      offlineVenueWine: [],
      offlineCategories: [],
      fileType: "venueWine",
      importDate: new Date(),
      dateDidChange: false
    };
  },
  created() {
    // use consts in template:
    this.VENUEWINE_TYPE = VENUEWINE_TYPE;
    this.REGION_TYPE = REGION_TYPE;
    this.GRAPE_TYPE = GRAPE_TYPE;
    this.EK = EK;
  },
  methods: {
    pushProtocolMsg(msg, forcePush = false) {
      if (forcePush == true || this.protocolActive == true) {
        this.progress.push(msg + "\n");
      }
    },
    clearData() {
      this.csvInput = [];
      this.progress = [];
      this.importingWineIndex = 0;
      this.importingWinesLength = 0;
      this.protocolTableData = [];
      this.protocolTableDataCSV = "";
      this.offlineWeingut = [];
      this.offlineRebsorte = [];
      this.offlineWineSupplier = [];
      this.offlineBottleSize = [];
      this.offlineLand = [];
      this.offlineWineType = [];
      this.offlineRegion = [];
      this.offlineWine = [];
      this.offlineVenueWine = [];
    },
    async getOfflineData() {
      this.pushProtocolMsg("Lade Offline Cache Daten", true);

      const venueId = this.$store.getters.getVenue.id;

      if (this.fileType == VENUEWINE_TYPE || this.fileType == EK) {
        const wineriesResponse = await DataService.getWineries();
        this.offlineWeingut = wineriesResponse["data"].data;

        this.pushProtocolMsg(
          "Weingüter in Datenbank gefunden: " + this.offlineWeingut.length,
          true
        );

        const wineSupplierResponse = await DataService.getAllWineSuppliers(
          venueId
        );
        this.offlineWineSupplier = wineSupplierResponse["data"].data;

        this.pushProtocolMsg(
          "Lieferanten in Datenbank gefunden: " +
            this.offlineWineSupplier.length,
          true
        );

        const bottleSizeResponse = await DataService.getBottleSizes();
        this.offlineBottleSize = bottleSizeResponse["data"].data;

        this.pushProtocolMsg(
          "Flaschengrößen in Datenbank gefunden: " +
            this.offlineBottleSize.length,
          true
        );

        const winesResponse = await DataService.getWines();
        this.offlineWine = winesResponse["data"].data;

        this.pushProtocolMsg(
          "Weine (wine) in Datenbank gefunden: " + this.offlineWine.length,
          true
        );

        const venueWineResponse = await DataService.getVenueWines();
        this.offlineVenueWine = venueWineResponse["data"].data;
        var anzahlVenueWines =
          this.offlineVenueWine != null ? this.offlineVenueWine.length : 0;
        this.pushProtocolMsg(
          "Institutions-Weine (venueWine) in Datenbank gefunden: " +
            anzahlVenueWines,
          true
        );
      }

      const landResponse = await DataService.getAllLands();
      this.offlineLand = landResponse["data"].data;

      this.pushProtocolMsg(
        "Länder in Datenbank gefunden: " + this.offlineLand.length,
        true
      );

      const wineTypeResponse = await DataService.getAllWineTypes();
      this.offlineWineType = wineTypeResponse["data"].data;

      this.pushProtocolMsg(
        "Farben in Datenbank gefunden: " + this.offlineWineType.length,
        true
      );

      const regionResponse = await DataService.getAllCuratedRegions();
      this.offlineRegion = regionResponse["data"].data;

      this.pushProtocolMsg(
        "Regionen in Datenbank gefunden: " + this.offlineRegion.length,
        true
      );

      const gvaResponse = await DataService.getAllCuratedGrapeVarieties();
      this.offlineRebsorte = gvaResponse["data"].data;

      this.pushProtocolMsg(
        "Rebsorten in Datenbank gefunden: " + this.offlineRebsorte.length,
        true
      );

      const categoriesResponse = await DataService.getAllCategories();
      this.offlineCategories = categoriesResponse["data"].data;

      this.pushProtocolMsg(
        "Kategorien in Datenbank gefunden: " + this.offlineCategories.length,
        true
      );
    },
    loadTextFromFile(ev) {
      this.clearData();

      this.pushProtocolMsg("Lese CSV Datei ein", true);

      const file = ev.target.files[0];

      var count = 0;
      const self = this;
      var csvinput = [];
      var csverror = [];

      this.$papa.parse(file, {
        //worker: true, // Don't bog down the main thread if its a big file
        header: true,
        download: true,
        transformHeader: function (h) {
          return h.trim();
        },
        step: function (result) {
          // do stuff with result
          count = count + 1;
          if (result.errors.length == 0) {
            csvinput.push(result.data);
          } else {
            csverror.push(result.errors[0]);
          }
        },
        complete: function () {
          self.pushProtocolMsg(
            csvinput.length + " Zeilen in CSV Datei gefunden",
            true
          );

          if (csverror.length > 0) {
            self.pushProtocolMsg(
              "Errors in CSV Datei gefunden. Siehe Console Output",
              true
            );
          }

          self.csvInput = csvinput;
        },
      });
    },
    startImport() {
      if (this.csvInput.length > 0) {
        this.pushProtocolMsg("+++-------- START Wein Import -------+++", true);
        if (this.protocolActive == false) {
          this.pushProtocolMsg(
            "Protokoll deaktiviert. Nachrichten über den Wein Import Verlauf werden nicht angezeigt.",
            true
          );
        }
        if (this.fileType == VENUEWINE_TYPE || this.fileType == EK) {
          this.saveCSV(this.csvInput);
        } else {
          if (this.fileType == REGION_TYPE) {
            this.saveRegions(this.csvInput);
          }
          if (this.fileType == GRAPE_TYPE) {
            this.saveGrapeVarieties(this.csvInput);
          }
        }
      } else {
        this.pushProtocolMsg("CSV Datei ist leer!", true);
      }
    },
    async getWinery(winery) {
      try {
        this.pushProtocolMsg(
          "Suche Weingut " + winery.attributes.title + " ..."
        );
        //first look in cache
        const weingut = this.offlineWeingut.find((weingut) => {
          if (weingut.attributes.region == null) {
            console.log(weingut.attributes.title + ' fehlt Region');
            return false;
          }
          if (weingut.attributes.land == null) {
            console.log(weingut.attributes.title + ' fehlt Land');
            return false;
          }
          const foundTitle =
            weingut.attributes.title === winery.attributes.title;
          const foundRegion =
            weingut.attributes.region.data.id === winery.attributes.region.id;
          const foundLand =
            weingut.attributes.land.data.id === winery.attributes.land.id;

  
          var foundVenue = true;

          if (weingut.attributes.venue && weingut.attributes.venue.id != null) {
            if (
              weingut.attributes.venue.id === this.$store.getters.getVenue.id
            ) {
              foundVenue = true;
            } else {
              foundVenue = false;
            }
          }
          return foundTitle && foundRegion && foundLand && foundVenue;
        });

        if (weingut) {
          this.pushProtocolMsg("Weingut offline gefunden");
          return weingut;
        }

        //then look in api - TODO Delete fallback look in API
        const wineryModel = new Winery()
				wineryModel.title = winery.attributes.title
				wineryModel.region.id = winery.attributes.region.id
				wineryModel.land.id = winery.attributes.land.id


        let response = await DataService.findWinery(wineryModel);


        if (response["data"].data != null && response["data"].data.length > 0) {
          // TODO loop 
          response["data"].data.forEach((weingutFromAPI) => {
            var foundVenue = true;
            if (
              weingutFromAPI.attributes.venue &&
              weingutFromAPI.attributes.venue.data
            ) {
              if (
                weingutFromAPI.attributes.venue.data.id ===
                this.$store.getters.getVenue.id
              ) {
                foundVenue = true;
                weingutFromAPI["attributes"]["venue"] = {
                  id: this.$store.getters.getVenue.id,
                }; // add venue id on same level as in offline weinguts
              } else {
                foundVenue = false;
              }
            }
            if (foundVenue) {
              this.pushProtocolMsg("Weingut gefunden");
              this.offlineWeingut.push(weingutFromAPI);
              return weingutFromAPI;
            }
          })  
        }

        //when checkbox was checked to skip import on missing winery
        if (this.skipImportWithErrors == true) {
          this.pushProtocolMsg(
            "Weingut nicht gefunden, Skip aktiv, wird übersprungen..."
          );
          return null;
        } else {
          this.pushProtocolMsg("Weingut nicht gefunden, wird angelegt...");

          if (this.flagErrorsWithVenueId == true) {
            this.pushProtocolMsg("Weingut wird mit venue ID geflagged...");
            winery["attributes"]["venue"] = {
              id: this.$store.getters.getVenue.id,
            };
          } else {
            winery["attributes"]["venue"] = null;
          }
          var postObject = {
            data: { ...winery.attributes },
          };
          let responsePost = await DataService.postWinery(postObject);
          var wineryFromStrapiPost = responsePost["data"].data;

          if (this.flagErrorsWithVenueId == true) {
            //set this flag to later create wine with venue flag
            wineryFromStrapiPost["importWineWithVenueField"] = true;
          }

          assignObjectFromPath(wineryFromStrapiPost, ['attributes', 'region','data', 'id'],  winery.attributes.region.id)
          assignObjectFromPath(wineryFromStrapiPost, ['attributes', 'land','data', 'id'],  winery.attributes.land.id)
          
          this.offlineWeingut.push(wineryFromStrapiPost);
          return wineryFromStrapiPost;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createORUpdateCuratedRegion(region) {
      try {
        this.pushProtocolMsg("Suche Region " + region.title + " ...");

        const offlineRegion = this.offlineRegion.find(
          (element) =>
            element.attributes.title === region.title && element.attributes.curated === true
        );
        if (offlineRegion) {
          this.pushProtocolMsg("kuratierte Region offline gefunden");
          return offlineRegion;
        }

        let response = await DataService.findRegion(region.title);

        if (response["data"].data != null && response["data"].data.length > 0) {
          //set id if exists one

          this.pushProtocolMsg("Region bei Strapi gefunden");
          var regionFromStrapi = response["data"].data[0];
          region.id = regionFromStrapi.id;
          if (regionFromStrapi.attributes.curated == true) {
            this.offlineRegion.push(regionFromStrapi);
            return regionFromStrapi;
          } else {
            this.pushProtocolMsg("Region nicht kuratiert");
          }
        }
        region.curated = true;
        this.pushProtocolMsg("Region wird angelegt oder bearbeitet...");
        var responsePost = {};
        if (region.id != null) {
          responsePost = await DataService.putRegion(region);
        } else {
          responsePost = await DataService.postRegion(region);
        }
        var regionFromStrapiPost = responsePost["data"].data;

        this.offlineRegion.push(regionFromStrapiPost);
        return regionFromStrapiPost;
      } catch (error) {
        console.log(error);
      }
    },
    async getRegion(region) {
      try {
        this.pushProtocolMsg("Suche Region " + region.title + " ...");
        var title = region.title.trim()
        // Mini fix for some region names
        if (title === "Burgund") {
          title = "Burgundy";
        }
        if (title === "Piemont") {
          title = "Piedmont";
        }
        if (title === "Elsass") {
          title = "Alsace";
        }
        if (title === "Abruzzen") {
          title = "Abruzzo";
        }
        if (title === "Katalonien") {
          title = "Cataluña";
        }
        if (title === "Savoyen") {
          title = "Savoie";
        }
        if (title === "Kastilien" || title === "Castilla y León" || title === "Kastilien-Leon") {
          title = "Castilla-y-León";
        }
       
        if (title === "Südtirol") {
          title = "Alto Adige";
        }
        
        if (title === "Galicien" || title === "Galizien") {
          title = "Galicia";
        }
        if (title === "Ardeche") {
          title = "Ardèche";
        }

        if (title === "Sizilien") {
          title = "Sicilia";
        }
        if (title === "Lombardai") {
          title = "Lombardia";
        }
        if (title === "Umbrien") {
          title = "Umbria";
        }
        if (title === "Languedoc" || title === "Roussillon") {
          title = "Languedoc-Roussillon";
        }
        if (title === "Lissabon") {
          title = "Lisboa";
        }
        if (title=== "Tokaj")
        {
          title = "Tokaj-Hegyalja";
        }
        if (title === "Melon de Bourgogne"){
          title = "Melon"
        }

        const offlineRegion = this.offlineRegion.find(
          (element) => equalsIgnoreCase(element.attributes.title, title)
        );
        if (offlineRegion) {
          this.pushProtocolMsg("Region offline gefunden");
          return offlineRegion;
        }
        this.pushProtocolMsg( "Region nicht gefunden, wird übersprungen...")
        return null
        
      } catch (error) {
        console.log(error);
      }
    },
    async getLand(land) {
      try {
        this.pushProtocolMsg("Suche Land " + land.title + " ...");
        var title = land.title.trim()
        const offlineLand = this.offlineLand.find(
          (element) => equalsIgnoreCase(element.attributes.title, title)
        );
        if (offlineLand) {
          this.pushProtocolMsg("Land offline gefunden");
          return offlineLand;
        }
        this.pushProtocolMsg("Land offline nicht gefunden. Überspringe...");
        return null
      } catch (error) {
        console.log(error);
      }
    },
    async getGrapeVariety(gva) {
      try {
        this.pushProtocolMsg("Suche Rebsorte " + gva.title + " ...");
        var title = gva.title.trim()
        if (title === "Spätburgunder") {
          title = "Pinot Noir";
        }
        if (title === "Grauburgunder") {
          title = "Pinot Gris";
        }
        if (title === "Müller Thurgau") {
          title = "Müller-Thurgau";
        }
        if (title === "Sylvaner") {
          title = "Silvaner";
        }
        if (title === "Ploussard") {
          title = "Poulsard";
        }

        const offlineRebsorte = this.offlineRebsorte.find(
          (element) => equalsIgnoreCase(element.attributes.title, title)
        );
        if (offlineRebsorte) {
          this.pushProtocolMsg("Rebsorte offline gefunden");
          return offlineRebsorte;
        }
        this.pushProtocolMsg("Rebsorte offline nicht gefunden");
        return null
        
      } catch (error) {
        console.log(error);
      }
    },
    async getCategory(title) {
      try {
        this.pushProtocolMsg("Suche Kategorie " + title + " ...");
        var titleTrim = title.trim()
        console.log(this.offlineCategories, 'offlineCategories')
        const offlineCategory = this.offlineCategories.find(
          (element) => equalsIgnoreCase(element.attributes.title, titleTrim)
        );
        if (offlineCategory) {
          this.pushProtocolMsg("Kategorie offline gefunden");
          return offlineCategory;
        }
        this.pushProtocolMsg("Kategorie offline nicht gefunden");
        return null
      } catch (error) {
        console.log(error);
      }
    },

    async createORUpdateCuratedGrapeVariety(grapeVariety, wineTypeId, landId) {
      try {
        
        this.pushProtocolMsg("Suche Rebsorte " + grapeVariety.title + " ...");

        const offlineRebsorte = this.offlineRebsorte.find(
          (element) =>
          equalsIgnoreCase(element.attributes.title, grapeVariety.title) && element.attributes.curated === true
        );
        var foundColor = false
        var foundLand = false
        if (offlineRebsorte && offlineRebsorte.wine_types != null && offlineRebsorte.wine_types.data != null && offlineRebsorte.lands != null && offlineRebsorte.lands.data != null) {
         
          for(var i = 0; i < offlineRebsorte.wine_types.data.length; i++) {
              if (offlineRebsorte.wine_types.data[i].id == wineTypeId) {
                foundColor = true;
                  break;
              }
          }

          for(var la = 0; la < offlineRebsorte.lands.data.length; +la) {
              if (offlineRebsorte.lands.data[la].id == landId) {
                foundLand = true;
                  break;
              }
          }

          
          this.pushProtocolMsg("kuratierte Rebsorte offline gefunden");
          if (!foundColor || !foundLand) {
            this.pushProtocolMsg("WineTypeID "+ wineTypeId+ " oder Land "+ landId + " gibt es noch nicht offline");
          } else {
            return offlineRebsorte
          }
          
        }

        let response = await DataService.findGrapeVariety(grapeVariety.title);

        if (response["data"].data != null && response["data"].data.length > 0) {
          //set id if exists one

          this.pushProtocolMsg("Rebsorte bei Strapi gefunden");
          var grapeVarietyFromStrapi = response["data"].data[0];
          grapeVariety.id = grapeVarietyFromStrapi.id;
          foundColor = false
          // look for winetype list in strapi object and generate an object list that are able to be put or pushed
          var foundWineTypes = []
          for(var j = 0; j < grapeVarietyFromStrapi.attributes.wine_types.data.length; j++) {
            foundWineTypes.push({id: grapeVarietyFromStrapi.attributes.wine_types.data[j].id})
            if (grapeVarietyFromStrapi.attributes.wine_types.data[j].id == wineTypeId) {
              foundColor = true;
            }
          }
          // add winetype list to object
          grapeVariety.wine_types.push(...foundWineTypes)

          //same for lands:
          var foundLands = []
          foundLand = false
          for(var l = 0; l < grapeVarietyFromStrapi.attributes.lands.data.length; l++) {
            foundLands.push({id: grapeVarietyFromStrapi.attributes.lands.data[l].id})
            if (grapeVarietyFromStrapi.attributes.lands.data[l].id == landId) {
              foundLand = true
            }
          }
         
          grapeVariety.lands.push(...foundLands)

          if (grapeVarietyFromStrapi.attributes.curated == true) {
            // return strapi object when land and color are alredy found
            if (foundColor && foundLand) {
              this.pushProtocolMsg("Weinfarbe und Land vorhanden");
              this.offlineRebsorte.push(grapeVarietyFromStrapi);
              return grapeVarietyFromStrapi;
            } else {
              this.pushProtocolMsg("Weinfarbe oder Land ist noch nicht vorhanden");
            }
           
          } else {
            this.pushProtocolMsg("Rebsorte noch nicht kuratiert");
          }
        }
        grapeVariety.curated = true;
        // add new object with wineTypeId:
        if (!foundColor) {
          grapeVariety.wine_types.push({id: wineTypeId})
        }
          // add new object with landId
        if (!foundLand) {
          grapeVariety.lands.push({id: landId})
        }
       
        this.pushProtocolMsg("Rebsorte wird angelegt oder bearbeitet...");
        var responsePost = {};
        if (grapeVariety.id != null) {
          
          responsePost = await DataService.putGrapeVariety(grapeVariety);
        } else {
          responsePost = await DataService.postGrapeVariety(grapeVariety);
        }

        var regionFromStrapiPost = responsePost["data"].data;

        this.offlineRegion.push(regionFromStrapiPost);
        return regionFromStrapiPost;
      } catch (error) {
        console.log(error);
      }
    },
    async getWineType(wt) {
      try {
        this.pushProtocolMsg("Suche Farbe " + wt.title + " ...");
        var title = wt.title.trim()
        const offlineWineType = this.offlineWineType.find(
          (element) => equalsIgnoreCase(element.attributes.title, title)
        );
        if (offlineWineType) {
          this.pushProtocolMsg("Farbe offline gefunden");
          return offlineWineType;
        }
        this.pushProtocolMsg("Farbe offline nicht gefunden, überspringe den Wein");
        return null
      } catch (error) {
        console.log(error);
      }
    },

    //------------- END OF Getters that are used only for Wines -------------------

    /**
     * find bottle size by number string like: '0.75'
     * @param {*} bottleSize
     */
    async getBottleSize(bottleSize) {

      if (bottleSize != null && Number(bottleSize) === 375 || bottleSize === '375' || bottleSize === '0,375'){
        bottleSize = 0.375;
      }
      try {
        this.pushProtocolMsg("Suche Flaschengröße " + bottleSize + " ...");

        var bs = -1.0;
        if (isNaN(Number(bottleSize))) {
          bs = parseFloat(bottleSize.replace(",", "."));
        } else {
          bs = Number(bottleSize);
        }
        const offlineBottleSize = this.offlineBottleSize.find(
          (element) => element.attributes.amount === bs
        );
        if (offlineBottleSize) {
          this.pushProtocolMsg("Flaschengröße offline gefunden");
          return offlineBottleSize;
        }

        this.pushProtocolMsg("Flaschengröße nicht gefunden");
        return null;
      } catch (error) {
        console.log(error);
      }
    },

    //Bezugsquelle
    async getWineSupplier(name, email) {
      try {
        this.pushProtocolMsg("Suche Bezugsquelle " + name + " ...");
        const offlineWineSupplier = this.offlineWineSupplier.find(
          (element) => equalsIgnoreCase(element.attributes.title, name.trim())
        );
        if (offlineWineSupplier) {
          this.pushProtocolMsg("Bezugsquelle offline gefunden");
          return offlineWineSupplier;
        }

        let response = await DataService.findWineSupplier(name);

        if (response["data"].data != null && response["data"].data.length > 0) {
          //set id if exists one
          this.pushProtocolMsg("Bezugsquelle gefunden");
          var supFromStrapi = response["data"].data[0];
          this.offlineWineSupplier.push(supFromStrapi);
          return supFromStrapi;
        } else {
          if (this.skipImportWithErrors == true && name !== "Sammellieferant") {
            this.pushProtocolMsg(
              "Bezugsquelle nicht gefunden, Skip aktiv, wird übersprungen..."
            );
            return null;
          } else {
            this.pushProtocolMsg(
              "Bezugsquelle nicht gefunden, wird angelegt..."
            );
            var newWineSupplier = {
              id: null
            };
            assignObjectFromPath(
              newWineSupplier,
              ["attributes", "title"],
              name
            );
            assignObjectFromPath(
              newWineSupplier,
              ["attributes", "address", "city"],
              ""
            );
            assignObjectFromPath(
              newWineSupplier,
              ["attributes", "address", "zip"],
              ""
            );
            assignObjectFromPath(
              newWineSupplier,
              ["attributes", "email"],
              email
            );
            assignObjectFromPath(
              newWineSupplier,
              ["attributes", "venues"],
              [this.$store.getters.getVenue.id]
            );
            let responsePost = await DataService.createWineSupplier(
              newWineSupplier
            );
            if (responsePost.data != null && responsePost.data.data != null) {
              this.offlineWineSupplier.push(responsePost.data.data);
              return responsePost.data.data;
            } else {
              this.pushProtocolMsg("Fehler beim Anlegen der Bezugsquelle!!");
              let protocolTableRowData = { 'Error': "Fehler beim Anlegen der Bezugsquelle " + name}
              this.pushToProtocolTable(protocolTableRowData)
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    getWineName(name) {
      this.pushProtocolMsg("Suche Weinname " + name + " ...");
      const offlineWine = this.offlineWine.find(
        (element) =>equalsIgnoreCase(element.attributes.title, name.trim())
      );
      if (offlineWine) {
        this.pushProtocolMsg("Weinname offline gefunden");
        return offlineWine.title;
      }

      this.pushProtocolMsg("Weinname nicht gefunden");
      return null;
    },

    getSellingPrice(vk) {
      if (vk) {
        if (isNaN(Number(vk))) {
          return parsePotentiallyGroupedFloat(vk);
        } else {
          return Number(vk);
        }
      } else {
        this.pushProtocolMsg("Einkaufspreis nicht gefunden");
        return 0.0;
      }
    },

  

    async updateWine(wine) {
      console.log(wine);
      /**
               *  {grapeVariety: 54
                  land: 1
                  region: 1
                  title: "Spätburgunder Rose"
                  venue: null
                  wineType: 55
                  winery: 1,
                  wineryTitle: "Weingut Schloss Proschwitz"
                  } 
               */
      try {
        this.pushProtocolMsg("Suche Wein " + wine.title + " ...", true);
        const offlineWine = this.offlineWine.find((element) => {
          if (element.attributes.title == null) {
            this.pushProtocolMsg("Einem Offline Wein fehlt der Titel!!", true);
          }
          const foundTitle = equalsIgnoreCase(element.attributes.title, wine.title)

          if (element.attributes.grapeVariety == null || element.attributes.grapeVariety.data == null) {
            this.pushProtocolMsg("Offline Wein " + element.attributes.title + " fehlt die Rebsorte!!", true);
          }
          const foundGva = element.attributes.grapeVariety.data.id === wine.grapeVariety;
          if (element.attributes.wineType == null || element.attributes.wineType.data == null) {
            this.pushProtocolMsg("Offline Wein " + element.attributes.title + " fehlt die Farbe!!", true);
          }
          const foundWineType = element.attributes.wineType.data.id === wine.wineType;
          if (element.attributes.land == null || element.attributes.land.data == null) {
            this.pushProtocolMsg("Offline Wein " + element.attributes.title + " fehlt das Land!!", true);
          }
          const foundLand = element.attributes.land.data.id === wine.land;
          if (element.attributes.region == null || element.attributes.region.data == null) {
            this.pushProtocolMsg("Offline Wein " + element.attributes.title + " fehlt die Region!!", true);
          }
          const foundRegion = element.attributes.region.data.id === wine.region
         
          var foundWinery = false;
          if (
            element.attributes.winery != null &&
            element.attributes.winery.data != null
          ) {
            foundWinery = element.attributes.winery.data.id === wine.winery;
          }

          var foundVenue = true;
          if (element.attributes.venue && element.attributes.venue.data && wine.venue) {
            if (element.attributes.venue.data.id === wine.venue) {
              foundVenue = true;
            } else {
              foundVenue = false;
            }
          }
          
          return (
            foundTitle &&
            foundWinery &&
            foundRegion &&
            foundLand &&
            foundGva &&
            foundWineType &&
            foundVenue
          );
        });
        if (offlineWine) {
          this.pushProtocolMsg("Wein offline gefunden", true);
          return offlineWine;
        }

        // if not found offline and EK selected -> skip:
        if (this.fileType == EK) {
          this.pushProtocolMsg("ACHTUNG! Wein NICHT offline gefunden", true);
          return null
        }

        // use search for wine 
        let response = await DataService.searchWines(wine.title + " " + wine.wineryTitle);
        console.log(response, 'Weinsuche Response');
        if (response.data != null && response.data.length > 0) {
          console.log(response.data[0], 'Weinsuche Response 0');
          var wtFromSearch = response.data[0];
          if (wtFromSearch._score > 10) {
           
            assignObjectFromPath(
              wtFromSearch,
              ["attributes", "title"],
              wtFromSearch.title
            );
        
            this.pushProtocolMsg("Wein in Search gefunden", true);
            return wtFromSearch;
          }
        }
        this.pushProtocolMsg("Wein nicht gefunden, wird angelegt...", true);

        let responsePost = await DataService.postWine(wine);
        var newOfflineWine = responsePost.data.data;
        assignObjectFromPath(
          newOfflineWine,
          ["attributes", "land", "data", "id"],
          wine.land ?? 0
        );
        assignObjectFromPath(
          newOfflineWine,
          ["attributes", "region", "data", "id"],
          wine.region ?? 0
        );
        assignObjectFromPath(
          newOfflineWine,
          ["attributes", "wineType", "data", "id"],
          wine.wineType ?? 0
        );
        assignObjectFromPath(
          newOfflineWine,
          ["attributes", "winery", "data", "id"],
          wine.winery ?? 0
        );
        assignObjectFromPath(
          newOfflineWine,
          ["attributes", "grapeVariety", "data", "id"],
          wine.grapeVariety ?? 0
        );

        this.offlineWine.push(newOfflineWine);
        return responsePost.data.data;
      } catch (error) {
        console.log(error)
        return null
      }
    },

    /**
     * try to get venueWine from offline cache, or create it if not found
     */
    async getVenueWine(venueWine) {
      try {
        this.pushProtocolMsg(
          "Suche Institutionswein mit Wein Id " +
            venueWine.attributes.wine +
            ", Jahr: " +
            venueWine.attributes.year +
            ", Fl.Größe Id: " +
            venueWine.attributes.bottleSize +
            ", Titel: " +
            venueWine.attributes.title +
            "..."
        ,true);
        const offlineVenueWine = this.getOfflineVenueWine(venueWine.attributes.title, venueWine.attributes.bottleSize, venueWine.attributes.year, venueWine.attributes.wine)
        
        if (offlineVenueWine) {
          this.pushProtocolMsg("Institutionswein offline gefunden");
          return offlineVenueWine;
        }

        //skip if not found offline 
        if (this.fileType == EK) {
          this.pushProtocolMsg("ACHTUNG!: Institutionswein NICHT offline gefunden");
          return null
        }

        this.pushProtocolMsg("Erstelle Institutionswein...");

        this.pushProtocolMsg("Ratingagencies hinzufügen...");
        applyMandatoryRatingAgencies(venueWine, this.getRatingAgencies)
        let responsePost = await DataService.createVenueWine(venueWine);

        this.pushProtocolMsg("Institutionswein angelegt");

        return responsePost.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    getOfflineVenueWine(title, bottleSizeId, year, wineId) {
      const offlineVenueWine = this.offlineVenueWine.find((element) => {
          const foundTitle =
            element.attributes.title === title;
          const foundBottleSize =
            element.attributes.bottleSize.data.id == bottleSizeId;
          const foundYear =
            element.attributes.year + "" === year + "";
          const foundWine =
            element.attributes.wine.data.id === wineId;

          return foundTitle && foundBottleSize && foundYear && foundWine;        
        })
        return offlineVenueWine
    },

    /**
     *
     * in: { id:, attributes: { title : .., wine:{},..}}
     * out: { id:, attributes: { title : ..}}
     */
    async updateVenueWine(venueWine) {
      try {
        this.pushProtocolMsg(
          "Suche Institutionswein mit Wein Id: " +
            venueWine.attributes.wine.data.id +
            ", Jahr: " +
            venueWine.attributes.year +
            ", Fl.Größe Id: " +
            venueWine.attributes.bottleSize.data.id +
            " ..."
        );

        const wineId = venueWine.attributes.wine.data.id;
        const bottleSizeId = venueWine.attributes.bottleSize.data.id;

        venueWine.attributes.wine = wineId;
        venueWine.attributes.bottleSize = bottleSizeId;


        let response = await DataService.findVenueWine(
          wineId,
          venueWine.attributes.year,
          bottleSizeId
        );
        if (response["data"].data != null && response["data"].data.length > 0) {
          //set id if exists one
          this.pushProtocolMsg("Institutionswein gefunden");
          var wtFromStrapi = response["data"].data[0];
          venueWine.id = wtFromStrapi.id;

          try {
              // unpack purchaseprices before update, remove supplier, only keep id
              if (
                venueWine.attributes.purchasePrices != null &&
                venueWine.attributes.purchasePrices.length > 0
              ) {
                for (var indexP in venueWine.attributes.purchasePrices) {
                  var purchasePrice = venueWine.attributes.purchasePrices[indexP];
                  if (
                    purchasePrice.id != null &&
                    purchasePrice.wineSupplier.data != null
                  ) {
                    delete purchasePrice.id;
                    purchasePrice.wineSupplier =
                      purchasePrice.wineSupplier.data.id;
                  }
                }
              }
              if (this.dateDidChange) {
                venueWine.attributes.newCreateDate = this.importDate;
              }
              let responseUpdate = await DataService.updateVenueWine(
              venueWine.id,
              venueWine
            );
            this.pushProtocolMsg("Institutionswein geändert");
            return responseUpdate.data.data;
          } catch (error) {
            this.pushProtocolMsg("da ging was schief");
            console.log(error);
          }
        } else {
          throw "VenueWine " + venueWine.wine.id + " not found";
        }
      } catch (error) {
        this.pushProtocolMsg(
          "Fehler beim Aktualisieren des Institutionsweins: " +
            venueWine.attributes.wine
        );
        console.log(error);
      }
    },

    /**
     * This is the main method when importing curated Regions
     *
     * @param {*} csvArray
     */
    async saveRegions(csvArray) {
      const self = this;

      //---0. Prepare Offline Cache
      await self.getOfflineData();

      var count = 0;
      self.importingWinesLength = csvArray.length;

      self.pushProtocolMsg(
        "++-------- Starte Regionen Import für " +
          csvArray.length +
          " Regionen -------++",
        true
      );

      for (var index in csvArray) {
        self.importingWineIndex = count;

        if (self.limitImport == true) {
          if (count >= self.limitImportCount) {
            self.pushProtocolMsg(
              "++------- Import Limit: " +
                self.limitImportCount +
                " Zeilen erreicht. Import wird beendet. -------++",
              true
            );
            return;
          }
        }
        let protocolTableRowData = this.generateBlankoObject(csvArray[index]) 

        self.pushProtocolMsg(
          "+-------- Starte Regionen-Import: " +
            index +
            "/" +
            csvArray.length +
            ": " +
            csvArray[index]["Region"] +
            " -------+"
        );

        //--- 1. Collect land data for the region

        var land = {};
        land["title"] = csvArray[index]["Land"];
        land = await self.getLand(land);
      
        if (land === null) {
          protocolTableRowData['Error'] =  "Land nicht vorhanden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        //--- 2. Add Land to region object
        if (land.id != null) {
          var region = {};
          region["title"] = csvArray[index]["Region"];
          region["curated"] = true;
          region["land"] = land["id"];
          if (  region["title"] != null &&  region["title"].length > 0) {
        //--- 3. Create OR update region in strapi
            region["title"] = region["title"].trim()
            await self.createORUpdateCuratedRegion(region);
          } else {
            self.pushProtocolMsg(
            "+-------- FEHLER bei Index: " + index )
          }
         
          
        } else {
          self.pushProtocolMsg(
            "+-------- FEHLER beim Land: " +
              csvArray[index]["Land"] +
              " -------+"
          );
        }

        self.pushProtocolMsg(
          "+-------- ENDE Region-Import: " +
            csvArray[index]["Region"] +
            " " +
            csvArray[index]["Land"] +
            " -------+"
        );
        count += 1;
      }

      self.pushProtocolMsg("+++-------- ENDE Regionen Import -------+++", true);
    },

    /**
     * This is the main method when importing (Venue-)Wines, the process takes several steps
     *
     * @param {*} csvArray
     */
    async saveCSV(csvArray) {
      const self = this;

      //---0. Prepare Offline Cache
      await self.getOfflineData();
   

      var count = 0;
      self.importingWinesLength = csvArray.length;

      self.pushProtocolMsg(
        "++-------- Starte Wein-Import für " +
          csvArray.length +
          " Weine -------++",
        true
      );

      for (var index in csvArray) {
        count += 1;
        self.importingWineIndex = count;

        let protocolTableRowData = this.generateBlankoObject(csvArray[index]) 

        if (self.limitImport == true) {
          if (count >= self.limitImportCount) {
            self.pushProtocolMsg(
              "++------- Import Limit: " +
                self.limitImportCount +
                " Zeilen erreicht. Import wird beendet. -------++",
              true
            );
            return;
          }
        }

        self.pushProtocolMsg(
          "+-------- Starte Wein-Import: " +
            index +
            "/" +
            csvArray.length +
            ": " +
            csvArray[index]["Weinname"] +
            " -------+"
        );

        //--- 1. Collect all relevant fields from strapi collections for the wine
        var region = {};
        region["title"] = csvArray[index]["Region"];
        region = await self.getRegion(region);
        if (region === null) {
          protocolTableRowData['Error'] =  "keine kuratierte Region gefunden.",
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var land = {};
        land["title"] = csvArray[index]["Land"];
        land = await self.getLand(land);
        if (land === null) {
          protocolTableRowData['Error'] =  "Land nicht vorhanden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var weingut = null;
        if (land !== null && region !== null) {
          weingut = {};
          assignObjectFromPath(
            weingut,
            ["attributes", "land", "id"],
            land["id"] ?? 0
          );
          assignObjectFromPath(
            weingut,
            ["attributes", "region", "id"],
            region["id"] ?? 0
          );
          assignObjectFromPath(
            weingut,
            ["attributes", "title"],
            csvArray[index]["Weingut"]
          );
          // weingut['attributes']['city'] = csvArray[index]['Ort']
          weingut = await self.getWinery(weingut);
        }

        if (weingut === null) {
          protocolTableRowData['Error'] = "Weingut wurde nicht gefunden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var grapeVariety = {};
        grapeVariety["title"] = csvArray[index]["Rebsorte"];

        grapeVariety = await self.getGrapeVariety(grapeVariety);
        if (grapeVariety === null) {
          protocolTableRowData['Error'] =  "keine kuratierte Rebsorte gefunden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var wineType = {};
        wineType["title"] = csvArray[index]["Farbe"];
        wineType = await self.getWineType(wineType);
        if (wineType === null) {
          protocolTableRowData['Error'] =  "Farbe nicht vorhanden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var category = {};
        category = await self.getCategory(csvArray[index]["Kategorie"]);
        if (category === null && csvArray[index]["Kategorie"] != null) {
          category = {};
          category["title"] = csvArray[index]["Kategorie"];
          var categoryResponse = await StrapiService.createCategory(category);
          category = categoryResponse.data.data;
          this.offlineCategories.push(category);
        }

        var weinname = null;
        weinname = self.getWineName(csvArray[index]["Weinname"]);

        //--- 2. create wine from all collected fields
        var wein = {};

        if (weinname) {
          wein["title"] = weinname;
        } else {
         
          wein["title"] = getWineNameNullChecked(csvArray[index]["Weinname"], csvArray[index]["Rebsorte"]);
        }

        if (weingut) {
          wein["winery"] = weingut["id"];
          wein["wineryTitle"] = weingut["attributes"]["title"];
        }

        if (region) {
          wein["region"] = region["id"];
        }

        if (grapeVariety) {
          wein["grapeVariety"] = grapeVariety["id"];
        }

        if (land) {
          wein["land"] = land["id"];
        }

        if (wineType) {
          wein["wineType"] = wineType["id"];
        }
        
        if (category) {
          wein["category"] = category["id"];
        }

        //--- 3. when skipping mode for wine imports is active, validate fields and
        //generate a log table with errors for later csv export

       
        if (self.skipImportWithErrors === true || this.fileType == EK) {
          var foundMissingData = false;
          if (land === null) {
            protocolTableRowData["Error"] += `Land: ${csvArray[index]["Land"]} | `;
            protocolTableRowData["Land"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (region === null) {
            protocolTableRowData[
              "Error"
            ] += `Region: ${csvArray[index]["Region"]} | `;
            protocolTableRowData["Region"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (grapeVariety === null) {
            protocolTableRowData[
              "Error"
            ] += `Rebsorte: ${csvArray[index]["Rebsorte"]} | `;
            protocolTableRowData["Rebsorte"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (wineType === null) {
            protocolTableRowData[
              "Error"
            ] += `Farbe: ${csvArray[index]["Farbe"]} | `;
            protocolTableRowData["Farbe"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (weingut === null) {
            protocolTableRowData[
              "Error"
            ] += `Weingut: ${csvArray[index]["Weingut"]} | `;
            protocolTableRowData["Weingut"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (weinname === null) {
            protocolTableRowData[
              "Error"
            ] += `Weinname: ${csvArray[index]["Weinname"]} | `;
            protocolTableRowData["Weinname"] = "ERR: Nicht gefunden";
            foundMissingData = true;
          }

          if (csvArray[index]["Jahrgang"]) {
            protocolTableRowData["Jahrgang"] = csvArray[index]["Jahrgang"];
          }

          if (csvArray[index]["Flaschengröße"]) {
            var bs = csvArray[index]["Flaschengröße"];
            protocolTableRowData["Flaschengröße"] = bs.replace(",", ".");
          }

          if (csvArray[index]["Kategorie"]) {
            protocolTableRowData["Kategorie"] = csvArray[index]["Kategorie"];
          }

          if (foundMissingData === true) {
            protocolTableRowData["Error"] += ` (${index}/${csvArray.length})`;

            self.pushToProtocolTable(protocolTableRowData);

            //--- 4.A skip this wine import and go on with next
            self.pushProtocolMsg(
              "Fehlende Wein-Daten - Überspringe Wein Import"
            );
            self.pushProtocolMsg(
              "+-------- ENDE Wein-Import: " +
                csvArray[index]["Weinname"] +
                " -------+"
            );
            continue;
          }
        }

        //--- 4.B create or update Wine

        //when we find errors during import and 'flagErrorsWithVenueId' is checked
        //we create a new wine and flag it with venueId so that we can use the wine only per venue
        if (self.flagErrorsWithVenueId == true) {
          if (
            "importWineWithVenueField" in weingut ||
            "importWineWithVenueField" in region ||
            "importWineWithVenueField" in grapeVariety ||
            "importWineWithVenueField" in land ||
            "importWineWithVenueField" in wineType ||
            weinname === null
          ) {
            wein["venue"] = self.$store.getters.getVenue.id;
            self.pushProtocolMsg(
              `Fehlende Wein-Daten - Importiere Wein ${wein["title"]} mit VenueId ${wein["venue"]}`,
              true
            );
          } else {
            wein["venue"] = null;
          }
        } else {
          wein["venue"] = null;
        }

        var wineFromStrapi = await self.updateWine(wein);
        // if wine doesnt already exists skip
        if (wineFromStrapi == null && this.fileType == EK) {
          self.protocolTableData.push(protocolTableRowData);
          continue
        }
        console.log(wineFromStrapi, 'wineFromStrapi');
        console.log(wein, 'wein');
        if (wineFromStrapi.attributes.category == null && wein.category != null) {
            // add category to offline wine
            wineFromStrapi.attributes.category = wein.category
            console.log(wineFromStrapi, 'wineFromStrapi + category');
            await DataService.updateWine(wineFromStrapi.id, wineFromStrapi)
          }
        //--- 5. Skip Venue Wine Import when we only want to import Wines from Master List

        if (self.importOnlyWine == true) {
          self.pushProtocolMsg("Überspringe VenueWine Import");
          self.pushProtocolMsg(
            "+-------- ENDE Wein-Import: " +
              csvArray[index]["Weinname"] +
              " -------+"
          );
          continue;
        }

        //--- 6. Collect required venueWine fields

   

        var bottleSize = {};
        if (csvArray[index]["Flaschengröße"] != null) {
          bottleSize = await self.getBottleSize(
            csvArray[index]["Flaschengröße"]
          );
          if (bottleSize == null) {
            protocolTableRowData['Error'] =  "Flaschengröße nicht vorhanden"
            self.protocolTableData.push(protocolTableRowData);
            continue
          }
        } else {
          bottleSize = await self.getBottleSize("0.75");
        }

        var venueWine = {
          attributes: {},
        };
        venueWine.attributes["title"] = wineFromStrapi.attributes.title;
        venueWine.attributes["wine"] = wineFromStrapi.id;
        var year = csvArray[index]["Jahrgang"];
        if (year == null || year.length == 0 || isNaN(Number(year)) || (typeof year === 'string' && year.trim() == '')) {
          year = 0;
        }
        venueWine.attributes["year"] = year;
        venueWine.attributes["bottleSize"] = bottleSize.id;
        venueWine.attributes["venue"] = self.$store.getters.getVenue.id;
        // --- add date
        if (this.dateDidChange) {
          venueWine.attributes.newCreateDate = this.importDate;
        }

        //--- 7. Find venueWine in offline cache or create it with api
        
        var venueWineFromStrapi = await self.getVenueWine(venueWine);
        if (venueWineFromStrapi == null && this.fileType == EK) {
          self.protocolTableData.push(protocolTableRowData);
          continue
        }
        venueWine = venueWineFromStrapi;
        //--- 8. Collect other venueWine fields

        // overwrite amount if exists in csv or set to 0 if new venuewine (otherwise use current amaount)
        var amount = csvArray[index]["Anzahl"]
        if ( amount != null && amount.length > 0) {

          if (isNaN(Number(amount))) {
            amount = amount.replace(",", ".");
          }
          venueWine.attributes["amount"] = amount;
        } else if (venueWine.attributes["amount"] == null){
          venueWine.attributes["amount"] = 0
        }
       

        // sellingprice
        venueWine.attributes["sellingPrice"] = self.getSellingPrice(
          csvArray[index]["VK"]
        );

        // venueWine['glassPrice']= csvArray[index]['Preis VK / Glas (€)']
        venueWine.attributes["shelfNumber"] = csvArray[index]["Regal"];

        //add purchase price if not in venueWine already
        /*
                    const purchasePriceObject = await self.getPurchasePrice(csvArray[index]['EK'], supplier.id, venueWine.id)
                    if (! venueWine['purchasePrices']) {
                        venueWine['purchasePrices'] = []
                    }
                    */
        var ek = csvArray[index]["EK"];
        var price = -1;
        if (isNaN(Number(ek))) {
          price = parsePotentiallyGroupedFloat(ek)
        } else {
          price = Number(ek);
        }
       
        var supplier = {};
        if (csvArray[index]["Bezugsquelle"]) { // checks against null and ''
          var email = csvArray[index]["E-Mail Adresse"]
          // empty strings are not allowed as email
          if (email == '' || email == ' ') {
            email = null
          }
          supplier = await self.getWineSupplier(
            csvArray[index]["Bezugsquelle"], email
          );
        } else {
          supplier = await self.getWineSupplier("Sammellieferant", null);
        }
    
        venueWine = addSupplierAndPrice(venueWine, price, supplier)

        //--- add verkostungsnotiz
        venueWine.attributes["comments"] = csvArray[index]["Verkostungsnotiz"];
         //--- add article number
        venueWine.attributes["articleNumber"] = csvArray[index]["Artikelnummer"];
        //--- add Kassennotiz
        venueWine.attributes["cashNote"] = csvArray[index]["Kassennotiz"];
       

        //--- 9. Update VenueWine
        const updatedVenueWine = await self.updateVenueWine(venueWine);
        //-- 10. update import cache
        self.offlineVenueWine = self.offlineVenueWine.filter(
          (vw) => vw.id !== updatedVenueWine.id
        ); //first remove
        self.offlineVenueWine.push(updatedVenueWine); //then (re)add

        self.pushProtocolMsg(
          "+-------- ENDE Wein-Import: " +
            csvArray[index]["Weinname"] +
            " " +
            csvArray[index]["Jahrgang"] +
            " " +
            csvArray[index]["Flaschengröße"] +
            " -------+"
        );
      }

      self.pushProtocolMsg("+++-------- ENDE Wein Import -------+++", true);
    },

    /**
     * This is the main method when importing (Venue)Wines, the process takes several steps
     *
     * @param {*} csvArray
     */
    async saveGrapeVarieties(csvArray) {
      const self = this;

      //---0. Prepare Offline Cache
      await self.getOfflineData();

      var count = 0;
      self.importingWinesLength = csvArray.length;

      self.pushProtocolMsg(
        "++-------- Starte Rebsorten Import für " +
          csvArray.length +
          " Regionen -------++",
        true
      );

      for (var index in csvArray) {
        self.importingWineIndex = count;

        if (self.limitImport == true) {
          if (count >= self.limitImportCount) {
            self.pushProtocolMsg(
              "++------- Import Limit: " +
                self.limitImportCount +
                " Zeilen erreicht. Import wird beendet. -------++",
              true
            );
            return;
          }
        }

        self.pushProtocolMsg(
          "+-------- Starte Rebsorten-Import: " +
            index +
            "/" +
            csvArray.length +
            ": " +
            csvArray[index]["Rebsorte"] +
            ": " +
            csvArray[index]["Farbe"] +
            " -------+"
        );
        let protocolTableRowData = this.generateBlankoObject(csvArray[index])
        //--- 1. Collect land and color data for the grapevariety

        var land = {};
        land["title"] = csvArray[index]["Land"];
        land = await self.getLand(land);
        if (land === null) {
          protocolTableRowData['Error'] =  "Land nicht vorhanden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }

        var type = {};
        type["title"] = csvArray[index]["Farbe"];
        type = await self.getWineType(type)
        if (type === null) {
          protocolTableRowData['Error'] =  "Farbe nicht vorhanden."
          this.pushToProtocolTable(protocolTableRowData)
          continue
        }
        //--- 2. Add Land to grapevariety object
        if (land.id != null && type.id != null) {
          var grape = {};
          grape["title"] = csvArray[index]["Rebsorte"];
          grape["curated"] = true;
          grape["lands"] = [];
          grape["wine_types"] = [];

          if (grape["title"] != null && grape["title"].length > 0) {
             //--- 3. Create OR update Grapevariety with new type (farbe) and new land id in strapi
            await self.createORUpdateCuratedGrapeVariety(grape, type["id"], land['id']);
          } else {
            self.pushProtocolMsg(
            "+-------- FEHLER bei Index: " + index )
          }
        
        } else {
          self.pushProtocolMsg(
            "+-------- FEHLER beim Land oder Typ: " +
              csvArray[index]["Land"] +
              csvArray[index]["Farbe"] +
              " -------+"
          );
        }

        self.pushProtocolMsg(
          "+-------- ENDE Rebsorte-Import: " +
            csvArray[index]["Rebsorte"] +
            " " +
            csvArray[index]["Land"] +
            " -------+"
        );
        count += 1;
      }

      self.pushProtocolMsg("+++-------- ENDE Rebsorten Import -------+++", true);
    },

    pushToProtocolTable(protocolTableRowData) {
      this.protocolTableData.push(protocolTableRowData)
    },

    convertProtocolTableDataToCSV() {
      var array = this.protocolTableData;
      const headers = this.fields.map((field) => field.key);
      array.unshift(headers);

      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ";";

          line += array[i][index];
        }
        str += line + "\r\n";
      }
      this.protocolTableDataCSV = str;
    },

    downloadCSV() {
      this.convertProtocolTableDataToCSV();
      var exportedFilename = "poolinq_import_error.csv";
      const csv = this.protocolTableDataCSV;

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    generateBlankoObject(currentRow) {
      return {
          Error: "",
          Weingut: currentRow["Weingut"],
          Weinname: currentRow["Weinname"],
          Region: currentRow["Region"],
          Land: currentRow["Land"],
          Farbe: currentRow["Farbe"],
          Rebsorte: currentRow["Rebsorte"],
          Jahrgang: currentRow["Jahrgang"],
          Flaschengröße: currentRow["Flaschengröße"],
      }
    }
  },
  computed: {
    fields() {
      return [
        {
          key: "Error",
          label: "Error",
        },
        {
          key: "Weingut",
          label: "Weingut",
        },
        {
          key: "Weinname",
          label: "Weinname",
        },
        {
          key: "Region",
          label: "Region",
        },
        {
          key: "Land",
          label: "Land",
        },
        {
          key: "Farbe",
          label: "Farbe",
        },
        {
          key: "Rebsorte",
          label: "Rebsorte",
        },
        {
          key: "Jahrgang",
          label: "Jahrgang",
        },
        {
          key: "Flaschengröße",
          label: "Flaschengröße",
        },
      ];
    },
    ...mapGetters(['getRatingAgencies'])
  },
  filters: {
    capitalize(word) {
      return word.toUpperCase();
    },
  },
  async mounted() {},
  watch: {
    importDate: function () {
      this.dateDidChange = true;
    },
  }
};
</script>
<style scoped>
.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
</style>